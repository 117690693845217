<template>
  <div class="checkbox__container">
    <div class="checkbox">
      <div class="checkbox-container">
        <div class="ripple">
          <input
            id="checkbox-6vrr8jlj"
            type="checkbox"
            true-value="true"
            value="1"
          />
        </div>
      </div>
      <label for="checkbox-6vrr8jlj" class="checkbox-label">Initial</label>
    </div>
    <div class="checkbox">
      <div class="checkbox-container hovered">
        <div class="ripple">
          <input
            id="checkbox-6vrr8jlj"
            type="checkbox"
            true-value="true"
            value="1"
          />
        </div>
      </div>
      <label for="checkbox-6vrr8jlj" class="checkbox-label">Hover</label>
    </div>
    <div class="checkbox checked">
      <div class="checkbox-container">
        <div class="ripple">
          <input
            id="checkbox-6vrr8jlj"
            type="checkbox"
            true-value="true"
            value="1"
          />
        </div>
      </div>
      <label for="checkbox-6vrr8jlj" class="checkbox-label">Checked</label>
    </div>
    <div class="checkbox checked md-disabled">
      <div class="checkbox-container disabled">
        <div class="ripple">
          <input
            id="checkbox-6vrr8jlj"
            type="checkbox"
            true-value="true"
            value="1"
            disabled
          />
        </div>
      </div>
      <label for="checkbox-6vrr8jlj" class="checkbox-label"
        >Checked - disabled</label
      >
    </div>
  </div>
</template>

<script>
// @TODO - fix styles on DS and put needed JS to toggle classes
export default {
  name: "CheckboxComponent",
  data: function() {
    return {
      checked: false,
    };
  },
  methods: {
    handleCheckboxClick: function() {
      this.checked = !this.checked;
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  width: auto;
  margin: 16px 16px 16px 0;
  position: relative;
}
.checkbox:not(.md-disabled),
.checkbox:not(.md-disabled) .checkbox-label {
  cursor: pointer;
}
.checkbox.checked .checkbox-container {
  background-color: #ef1217;
  border-color: transparent;
}
.checkbox .checkbox-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  border-radius: 2px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.checkbox .checkbox-container.disabled {
  background-color: gray;
}
.checkbox .checkbox-container.hovered {
  border-color: #ef1217;
}
.checkbox .checkbox-container::before {
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  z-index: 7;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.checkbox .checkbox-container::after,
.checkbox .checkbox-container::before {
  position: absolute;
  transition: 0.4s cubic-bezier(0.55, 0, 0.55, 0.2);
  content: " ";
}
.checkbox.checked .checkbox-container::after {
  border-color: white;
}
.checkbox.checked .checkbox-container::after {
  opacity: 1;
  transform: rotate(45deg) scale3D(1, 1, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.checkbox .checkbox-container::after {
  width: 6px;
  height: 13px;
  top: -1px;
  left: 4px;
  z-index: 8;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 2px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 2px;
  border-top: 0;
  border-top-color: currentcolor;
  border-left: 0;
  border-left-color: currentcolor;
  opacity: 0;
  transform: rotate(45deg) scale3D(0.15, 0.15, 1);
}
.checkbox .checkbox-container input {
  position: absolute;
  left: -999em;
}
.checkbox .checkbox-container {
  border-color: rgba(0, 0, 0, 0.54);
}
.checkbox .checkbox-label {
  height: 20px;
  padding-left: 16px;
  position: relative;
  line-height: 20px;
}
</style>
