<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Checkbox</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A checkbox is singular item representing one singular piece of
              data or input.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>
                A checkbox should never be used part of a group where only one
                option of the group should be selected. This behavior should be
                represented by radio buttons.
              </li>
              <li>
                A checkbox can be used as a group on a filter where each option
                is an additive filter.
              </li>
            </ul>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <div class="container mb-30">
              <h3>Checkbox</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
                    Vitae, est magnam! Velit nemo placeat id voluptates, atque quas, 
                    provident quos porro qui natus minus impedit expedita. Modi adipisci 
                    perspiciatis quam!
                  </p>
                </div>
            <div>Checkbox</div>
            <CodeView :component="primary.main">
              <CheckboxComponent />
            </CodeView>
          </div>
        </section>

        <!-- <div class="content-menu"></div> -->
      </div>
      <!-- <StickyTOC /> -->
      <SubFooter />
    </div>
  </div>
</template>

<script>
// import StickyTOC from "../StickyTOC";
import CheckboxComponent from "../CheckboxComponent";
import CodeView from "../CodeView";
import SubFooter from "../../components/SubFooter";
import TempLeftNav from "../TempLeftNav";
export default {
  name: "Checkbox",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML: `<div class="checkbox">
  <div class="checkbox-container">
    <div class="ripple">
      <input
        id="checkbox-1"
        type="checkbox"
        true-value="true"
        value="1"
      />
    </div>
  </div>
  <label for="checkbox-1" class="checkbox-label"
    >Initial</label
  >
</div>
<div class="checkbox">
  <div class="checkbox-container hovered">
    <div class="ripple">
      <input
        id="checkbox-2"
        type="checkbox"
        true-value="true"
        value="2"
      />
    </div>
  </div>
  <label for="checkbox-2" class="checkbox-label"
    >Hover</label
  >
</div>
<div class="checkbox checked">
  <div class="checkbox-container">
    <div class="ripple">
      <input
        id="checkbox-3"
        type="checkbox"
        true-value="true"
        value="3"
      />
    </div>
  </div>
  <label for="checkbox-3" class="checkbox-label"
    >Checked</label
  >
</div>
<div class="checkbox checked">
  <div class="checkbox-container disabled">
    <div class="ripple">
      <input
        id="checkbox-4"
        type="checkbox"
        true-value="true"
        value="4"
        disabled="disabled"
      />
    </div>
  </div>
  <label for="checkbox-4" class="checkbox-label"
    >Checked - disabled</label
  >
</div>`,
          codeCSS: `.checkbox {
  display: flex;
  width: auto;
  margin: 16px 16px 16px 0;
  position: relative;
}
.checkbox:not(.md-disabled),
.checkbox:not(.md-disabled) .checkbox-label {
  cursor: pointer;
}
.checkbox.checked .checkbox-container {
  background-color: #0096ff;
  border-color: transparent;
}
.checkbox .checkbox-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  border-radius: 2px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.checkbox .checkbox-container.disabled {
  background-color: gray;
}
.checkbox .checkbox-container.hovered {
  border-color: #0096ff;
}
.checkbox .checkbox-container::before {
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  z-index: 7;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.checkbox .checkbox-container::after,
.checkbox .checkbox-container::before {
  position: absolute;
  transition: 0.4s cubic-bezier(0.55, 0, 0.55, 0.2);
  content: " ";
}
.checkbox.checked .checkbox-container::after {
  border-color: white;
}
.checkbox.checked .checkbox-container::after {
  opacity: 1;
  transform: rotate(45deg) scale3D(1, 1, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.checkbox .checkbox-container::after {
  width: 6px;
  height: 13px;
  top: -1px;
  left: 4px;
  z-index: 8;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 2px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 2px;
  border-top: 0;
  border-top-color: currentcolor;
  border-left: 0;
  border-left-color: currentcolor;
  opacity: 0;
  transform: rotate(45deg) scale3D(0.15, 0.15, 1);
}
.checkbox .checkbox-container input {
  position: absolute;
  left: -999em;
}
.checkbox .checkbox-container {
  border-color: rgba(0, 0, 0, 0.54);
}
.checkbox .checkbox-label {
  height: 20px;
  padding-left: 16px;
  position: relative;
  line-height: 20px;
}`,
          codeJS: "// @TODO - ADD JS",
          preview: "This is the preview",
          name: "Checkbox - States",
        },
      },
    };
  },
  components: {
    CodeView,
    CheckboxComponent,
    TempLeftNav,
    SubFooter,
    // StickyTOC,
  },
};
</script>
